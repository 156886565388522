import { Layout } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import ChildSidebar from "@components/child/ChildSidebar";

import DocumentsTable from "../family_finding/documents/DocumentsTable";
import AgencyHumanSidebar from "./AgencyHumanSidebar";

const { t } = translationWithRoot("agency_human.family_finding_documents");

/** The full page layout of our Family Finding Documents page
 *
 * If the person the documents are for is a child in care, we show the child
 * sidebar rather than the more generic Agency Human sidebar
 */
const FamilyFindingDocuments = ({
  agencyHuman,
  agencyHumanName,
  childPermissions,
}) => {
  const { id, firstName, lastName, childId } = agencyHuman;
  // must use this hook to get the correct bolding applied to the sidebar link
  useCurrentPageSearchParam({ defaultPage: "family_finding_documents" });

  return (
    <Layout
      pageTitle={t("page_title", { name: agencyHumanName })}
      breadcrumbs={
        childId ? (
          <ChildBreadcrumbs childId={childId} childName={agencyHumanName} />
        ) : null
      }
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: agencyHumanName,
        collapsibleOnDesktop: true,
        content: childId ? (
          <ChildSidebar
            currentPage="family_finding_documents"
            avatar={{
              firstName,
              lastName,
            }}
            label={agencyHumanName}
            agencyHumanId={id}
            childId={childId}
            childFullName={agencyHumanName}
            childPermissions={childPermissions}
            modulePage="family_finding"
          />
        ) : (
          <AgencyHumanSidebar
            avatar={{
              firstName,
              lastName,
            }}
            label={agencyHumanName}
            agencyHumanId={id}
          />
        ),
      }}
      main={{
        content: <DocumentsTable agencyHumanId={id} />,
      }}
    />
  );
};
FamilyFindingDocuments.propTypes = {
  agencyHuman: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    childId: PropTypes.number,
  }),
  agencyHumanName: PropTypes.string.isRequired,
  childPermissions: PropTypes.shape({
    mayViewFamilyFindingSearches: PropTypes.bool,
  }).isRequired,
};

export default FamilyFindingDocuments;
