import { useMutation, useQuery } from "@apollo/client";
import {
  Actions,
  InputDropdown,
  LoadingOverlay,
  SurfaceForm,
  Text,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useState, Fragment } from "react";
import {
  previewFamilyFindingBulkOutreachCampaignPath,
  childRelationshipsDashboardPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import HtmlEditor from "@components/inputs/HtmlEditor";

import CreateBulkOutreachCampaign from "@graphql/mutations/CreateBulkOutreachCampaign.graphql";
import UpdateBulkOutreachCampaign from "@graphql/mutations/UpdateBulkOutreachCampaign.graphql";
import AgencyHumansByRelationshipIdsQuery from "@graphql/queries/AgencyHumansByRelationshipIds.graphql";
import BulkOutreachCampaign from "@graphql/queries/BulkOutreachCampaign.graphql";

import { getEncodedBase64SearchParams } from "@lib/base64SearchParams";
import preventDefault from "@lib/preventDefault";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import {
  B64PARAMS,
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
} from "@root/constants";

import { filteredAddresses } from "./BulkOutreachCampaignSidebar";

const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.draft"
);

const { t: contactLogsEnumT } = translationWithRoot(
  "activerecord.enums.family_finding_contact_log.status",
  {
    escapeJavascriptRoot: true,
  }
);

/**
 * Component for writing or editing a bulk outreach campaign draft.
 *
 * Constitues the main body of the Bulk Outreach Campaign page when
 * not previewing the letters
 */
const BulkOutreachCampaignDraft = ({ id, childId, childAgencyHumanId }) => {
  const [createBulkOutreachCampaign, { loading: createLoading }] = useMutation(
    CreateBulkOutreachCampaign
  );
  const [updateBulkOutreachCampaign, { loading: updateLoading }] = useMutation(
    UpdateBulkOutreachCampaign
  );
  const relationshipIds = useBase64SearchParam("selectedIds") || [];
  const { data: recipients = { agencyHumansByRelationshipIds: [] } } = useQuery(
    AgencyHumansByRelationshipIdsQuery,
    {
      variables: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationshipIds: relationshipIds,
      },
    }
  );
  const { data: { bulkOutreachCampaign } = {}, loading: campaignLoading } =
    useQuery(BulkOutreachCampaign, {
      variables: { id },
      skip: !id,
    });
  const { formState, setFormAttribute } = useBintiForm(bulkOutreachCampaign);
  const routeParams = `${B64PARAMS}=${getEncodedBase64SearchParams()}`;
  const contactAgencyHumanIds = recipients.agencyHumansByRelationshipIds.reduce(
    (ids, { agencyHuman: { id: ahId, addresses, childId: ahChildId } }) => {
      if (ahChildId) return ids;
      const filteredAddrs = filteredAddresses(addresses);
      return filteredAddrs.length ? [...ids, ahId] : ids;
    },
    []
  );

  const missingContentTemplate =
    // content of Quill editor is "<p><br></p>" when empty
    formState.contentTemplate === "<p><br></p>";

  const [showContentTemplateError, setShowContentTemplateError] =
    useState(false);

  const missingContactLogStatus = !formState.familyFindingContactLogStatus;

  const [showContactLogStatusError, setContactLogStatusError] = useState(false);

  return (
    <Fragment>
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        {t("step_two")}
      </Text>
      <LoadingOverlay active={campaignLoading}>
        <SurfaceForm
          title={t("relative_notification_letter")}
          actions={
            <Actions
              isSubmitting={createLoading || updateLoading}
              primaryDisabled={isEmpty(contactAgencyHumanIds)}
              primaryText={t("preview_letters")}
              primaryAction={preventDefault(async () => {
                let bulkOutreachCampaignId;
                // Show errors if either required field is missing
                // and skip kicking off a mutation
                if (missingContactLogStatus || missingContentTemplate) {
                  if (missingContactLogStatus) {
                    setContactLogStatusError(true);
                  } else {
                    // Clear error if it was shown previously
                    // and now the field is filled
                    setContactLogStatusError(false);
                  }
                  if (missingContentTemplate) {
                    setShowContentTemplateError(true);
                  } else {
                    // Clear error if it was shown previously
                    // and now the field is filled
                    setShowContentTemplateError(false);
                  }
                } else if (id) {
                  // If updating an existing bulk outreach campaign
                  ({
                    data: {
                      updateBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await updateBulkOutreachCampaign({
                    variables: {
                      id,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                      contactAgencyHumanIds,
                    },
                  }));
                } else {
                  // If creating a new bulk outreach campaign
                  ({
                    data: {
                      createBulkOutreachCampaign: {
                        bulkOutreachCampaign: { id: bulkOutreachCampaignId },
                      },
                    },
                  } = await createBulkOutreachCampaign({
                    variables: {
                      childAgencyHumanIds: [childAgencyHumanId],
                      contactAgencyHumanIds,
                      contentTemplate: formState.contentTemplate,
                      familyFindingContactLogStatus:
                        formState.familyFindingContactLogStatus,
                    },
                  }));
                }
                window.location = `${previewFamilyFindingBulkOutreachCampaignPath(
                  { id: bulkOutreachCampaignId }
                )}?${routeParams}`;
              })}
              cancelHref={`${childRelationshipsDashboardPath({
                id: childId,
              })}?${routeParams}`}
            />
          }
        >
          <InputDropdown
            required
            hideBlank
            label={t("contact_status")}
            onChange={setFormAttribute("familyFindingContactLogStatus")}
            value={formState.familyFindingContactLogStatus}
            /** We're intentionally leaving "returned" off of the draft view
             * as folks will never know that a letter was returned before it's sent
             */
            values={[
              {
                label: contactLogsEnumT("successful"),
                value: FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
              },
              {
                label: contactLogsEnumT("attempted"),
                value: FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
              },
            ]}
            error={showContactLogStatusError ? t("required_error") : null}
          />
          <HtmlEditor
            id="bulk-outreach-campaign-content"
            label={t("content")}
            name={t("content")}
            value={formState.contentTemplate}
            onChange={setFormAttribute("contentTemplate")}
            fullWidth
            required
            printOnly
            mergeFieldsClass="bulk_outreach_campaign"
            allowBlockQuotes={false}
          />
          <If condition={showContentTemplateError}>
            <Text textColor="danger-600" textStyle="supporting-50">
              {t("required_error")}
            </Text>
          </If>
        </SurfaceForm>
      </LoadingOverlay>
    </Fragment>
  );
};
BulkOutreachCampaignDraft.propTypes = {
  id: PropTypes.string,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
};

export default BulkOutreachCampaignDraft;
