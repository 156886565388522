import { InputHidden } from "@heart/components";
import I18n from "i18n-js";
import { isEqual, size, omit, has, includes, some, pick } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useRef, useEffect, useState } from "react";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

/** ### Usage
 *
 * A component for collecting how a person identifies their races
 */
const RaceWrapper = ({
  isRequired,
  selectedRaces,
  inputName,
  description = "",
}) => {
  const [races, setRaces] = useState(selectedRaces);

  return (
    <div>
      <NestedMultiFormInput
        options={window.Binti.RACE_OPTIONS}
        label={
          <Fragment>
            {I18n.t("views.common.race_select_title")}
            <If condition={isRequired}>*</If>
          </Fragment>
        }
        description={description}
        selectedOptions={races}
        onSelectedOptionsChange={newRaces => {
          setRaces(newRaces);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(races)} />
    </div>
  );
};

RaceWrapper.propTypes = {
  selectedRaces: PropTypes.object.isRequired,
  description: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
};

export default RaceWrapper;
