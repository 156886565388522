import { InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

/** ### Usage
 *
 * A component for collecting how a person identifies their ethnicities
 */
const EthnicityWrapper = ({
  selectedEthnicities,
  inputName,
  label,
  ethnicityOptions,
  description = "",
}) => {
  const [ethnicities, setEthnicities] = useState(selectedEthnicities);

  return (
    <div>
      <NestedMultiFormInput
        options={ethnicityOptions}
        label={label}
        description={description}
        selectedOptions={ethnicities}
        onSelectedOptionsChange={newEthnicities => {
          setEthnicities(newEthnicities);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(ethnicities)} />
    </div>
  );
};

EthnicityWrapper.propTypes = {
  selectedEthnicities: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputName: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  description: PropTypes.node,
};

export default EthnicityWrapper;
