import { useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  Icons,
  LoadingOverlay,
  Surface,
  TableCellList,
  Text,
  useConfirmModal,
} from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { childRelationshipsDashboardPath } from "routes";

import { translationWithRoot } from "@components/T";
import { displayAddress } from "@components/agency_humans/displayAgencyHumanInfo";

import AgencyHumansByRelationshipIdsQuery from "@graphql/queries/AgencyHumansByRelationshipIds.graphql";

import {
  getEncodedBase64SearchParams,
  setBase64SearchParams,
} from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import { B64PARAMS, ADDRESS_TYPE_MAILING } from "@root/constants";

const { Caption, Table, Thead, Tr, Th, Tbody, Td } = HeartTable;
const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.sidebar"
);

/** Returns all active mailing addresses or primary address if no mailing */
export const filteredAddresses = addresses => {
  const mailingAddresses = addresses.filter(
    ({ type, inactive }) => type.includes(ADDRESS_TYPE_MAILING) && !inactive
  );

  if (!isEmpty(mailingAddresses)) {
    return mailingAddresses;
  }
  return addresses.filter(({ primary }) => primary);
};

const BulkOutreachCampaignSidebar = ({
  childId,
  childAgencyHumanId,
  showChangeRecipientsButton,
  allowRemoveRecipients,
}) => {
  const { ConfirmModal, confirm } = useConfirmModal();

  const relationshipIds = useBase64SearchParam("selectedIds") || [];
  const { data = { agencyHumansByRelationshipIds: [] }, loading } = useQuery(
    AgencyHumansByRelationshipIdsQuery,
    {
      variables: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationshipIds: relationshipIds,
      },
    }
  );

  return (
    <Flex column gap="300">
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        <If condition={allowRemoveRecipients}>{t("step_one")}</If>
        <If condition={!allowRemoveRecipients}>{t("recipients")}</If>
      </Text>
      <Surface title={t("recipients")} hideTitle>
        <LoadingOverlay active={loading}>
          <Table>
            <Caption>
              <Flex justify="space-between">
                {t("recipients_with_count", { count: relationshipIds.length })}
                <If condition={showChangeRecipientsButton}>
                  <ConfirmModal submitText={t("confirm")} submitDangerButton>
                    {t("confirm_change_recipient")}
                  </ConfirmModal>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      confirm().then(isConfirmed => {
                        if (isConfirmed)
                          window.location = `${childRelationshipsDashboardPath({
                            id: childId,
                          })}?${B64PARAMS}=${getEncodedBase64SearchParams()}`;
                      })
                    }
                  >
                    {t("change_recipients")}
                  </Button>
                </If>
              </Flex>
            </Caption>
            <Thead>
              <Tr>
                <Th>{t("name")}</Th>
                <Th>{t("mailing_address")}</Th>
                <If condition={allowRemoveRecipients}>
                  <Th>{t("actions")}</Th>
                </If>
              </Tr>
            </Thead>
            <Tbody>
              {data.agencyHumansByRelationshipIds.map(
                ({ agencyHuman, relationshipId }) => {
                  const agencyHumanAddresses = filteredAddresses(
                    agencyHuman.addresses
                  );

                  return (
                    <Tr key={agencyHuman.id}>
                      <Td>{agencyHuman.fullName}</Td>
                      <Td>
                        <Choose>
                          <When condition={agencyHuman.childId}>
                            <Text textColor="danger-600">
                              <Icons.ExclamationTriangle />{" "}
                              {t("child_cannot_be_included")}
                            </Text>
                          </When>
                          <When condition={isEmpty(agencyHumanAddresses)}>
                            <Text textColor="danger-600">
                              <Icons.ExclamationTriangle />{" "}
                              {t("no_addresses_found")}
                            </Text>
                          </When>
                          <Otherwise>
                            <TableCellList
                              items={agencyHumanAddresses}
                              transformItem={displayAddress}
                            />
                          </Otherwise>
                        </Choose>
                      </Td>
                      <If condition={allowRemoveRecipients}>
                        <Td>
                          <Icons.Times
                            description={t("remove_recipient")}
                            onClick={() => {
                              setBase64SearchParams([
                                {
                                  attribute: "selectedIds",
                                  value: relationshipIds.filter(
                                    id => id !== relationshipId
                                  ),
                                },
                              ]);
                            }}
                          />
                        </Td>
                      </If>
                    </Tr>
                  );
                }
              )}
            </Tbody>
          </Table>
        </LoadingOverlay>
      </Surface>
    </Flex>
  );
};
BulkOutreachCampaignSidebar.propTypes = {
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  /** Whether or not to show the button to change recipients of a bulk outreach campaign */
  showChangeRecipientsButton: PropTypes.bool.isRequired,
  /** Whether or not to allow removing recipients */
  allowRemoveRecipients: PropTypes.bool.isRequired,
};

export default BulkOutreachCampaignSidebar;
