import {
  inputCommonPropTypes,
  InputGroupLayout,
} from "@heart/components/inputs/common";
import { entries, size, isEmpty, omitBy, pick } from "lodash";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import NestedMulti from "./NestedMulti";

const findOption = (path, options) => {
  const [prefix, suffix] = path.split(/\.(.*)/s, 2);
  const option = options.find(({ key }) => prefix === key);

  if (!option) return undefined;

  if (isEmpty(suffix)) return option;

  if (option.children) {
    return findOption(suffix, option.children);
  }

  return undefined;
};

const extractExclusivePaths = options => {
  const stack = [...options];
  const exclusivePaths = [];
  while (!isEmpty(stack)) {
    const option = stack.pop();
    // callers may or may not "camelize" keys
    if (option.mutually_exclusive || option.mutuallyExclusive) {
      exclusivePaths.push(option.path);
    }
    stack.push(...(option.children || []));
  }
  return exclusivePaths;
};

const handleMutuallyExclusives = (
  exclusivePaths,
  selectedOptions,
  newOption
) => {
  const getExclusiveOptions = candidates => pick(candidates, exclusivePaths);

  const previousExclusives = getExclusiveOptions(selectedOptions);
  const newValueIsExlusive = size(getExclusiveOptions(newOption)) > 0;

  // We picked a new exclusive option, uncheck everything else.
  if (newValueIsExlusive) {
    return newOption;
  }

  // We previously had an exclusive option
  if (size(previousExclusives) >= 1) {
    return newOption;
  }

  // Nothing exclusive was previously or currently selected, just append the new option
  return { ...selectedOptions, ...newOption };
};

/**
 * NestedMultiFormInput accepts a set of arbitrarily nested options,
 * an optional set of selectedOptions, and a callback onSelectedOptionsChange.
 *
 * onSelectedOptionsChange takes two arguments, the first is the new set of
 * options, the second is an object with a key "valid" which is true if the
 * options are valid and false otherwise.
 *
 * Treat it like a controlled input.
 *
 * Currently, the only use of NestedMultiFormInput is for the Ethnicity component.
 */
const NestedMultiFormInput = props => {
  const {
    options,
    selectedOptions: initialOptions = {},
    onSelectedOptionsChange,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState(initialOptions);

  // lightweight validation to check if everything has required details
  const isValid = newOptions =>
    entries(newOptions).every(([path, details]) => {
      const option = findOption(path, options);

      if (!option) return false;

      if (option.details?.required && isEmpty(details)) {
        return false;
      }

      if (!option.details && !isEmpty(details)) {
        return false;
      }

      return true;
    });

  const exclusivePaths = useMemo(
    () => extractExclusivePaths(options),
    [options]
  );

  const handlExclusiveOptions = newOption =>
    handleMutuallyExclusives(exclusivePaths, selectedOptions, newOption);

  const onOptionSelected = (path, details = null) => {
    // We only enforce mutual exclusivity when an option is changed for two reasons.
    // 1. We don't enforce it upon rendering because if there is a mutually exclusive
    // option selected alongside another option already stored a DB, it may be passed
    // to this component. We want to render acuratley the data passed in, but if edited,
    // we want to enforce current rules.
    // 2. We don't enforce it on unselecting b/c that simply can't put you into an
    // invalid state in terms of mutually exclusive options.

    const newOptions = handlExclusiveOptions({ [path]: details });

    setSelectedOptions(newOptions);
    onSelectedOptionsChange(newOptions, { valid: isValid(newOptions) });
  };

  const onOptionUnselected = path => {
    const newOptions = omitBy(selectedOptions, (_value, key) =>
      key.startsWith(path)
    );

    setSelectedOptions(newOptions);
    onSelectedOptionsChange(newOptions, { valid: isValid(newOptions) });
  };

  return (
    <InputGroupLayout
      visuallySeparated
      {...props}
      inputsComponent={() => (
        <NestedMulti
          {...{
            options,
            onOptionSelected,
            onOptionUnselected,
            selectedOptions,
          }}
        />
      )}
    />
  );
};

NestedMultiFormInput.propTypes = {
  ...inputCommonPropTypes,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.object,
  onSelectedOptionsChange: PropTypes.func.isRequired,
};

export default NestedMultiFormInput;
