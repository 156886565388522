import { Breadcrumbs, Layout } from "@heart/components";
import PropTypes from "prop-types";
import { childRelationshipsDashboardPath } from "routes";

import { translationWithRoot } from "@components/T";

import BulkOutreachCampaignDraft from "./BulkOutreachCampaignDraft";
import BulkOutreachCampaignPreview from "./BulkOutreachCampaignPreview";
import BulkOutreachCampaignSidebar from "./BulkOutreachCampaignSidebar";

const { t } = translationWithRoot("family_finding.bulk_outreach_campaigns");

/** Entry point for the Bulk Outreach Campaign flow for Family Finding */
const BulkOutreachCampaign = ({
  id,
  childAgencyHumanId,
  childId,
  childAgencyHumanFullName,
  previewStage,
}) => (
  <Layout
    pageTitle={t("page_title")}
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: childAgencyHumanFullName,
            href: childRelationshipsDashboardPath(childId),
          },
          {
            label: t("page_title"),
            href: "#",
          },
        ].filter(Boolean)}
      />
    }
    sidebar={{
      wide: true,
      title: t("sidebar.recipients"),
      content: (
        <BulkOutreachCampaignSidebar
          childId={childId}
          childAgencyHumanId={childAgencyHumanId}
          // Only show the change recipients button if we're creating a new campaign
          showChangeRecipientsButton={!id}
          // Only allow removing recipients when in draft mode
          allowRemoveRecipients={!previewStage}
        />
      ),
    }}
    main={{
      content: previewStage ? (
        <BulkOutreachCampaignPreview id={id} childId={childId} />
      ) : (
        <BulkOutreachCampaignDraft
          id={id}
          childId={childId}
          childAgencyHumanId={childAgencyHumanId}
        />
      ),
    }}
  />
);
BulkOutreachCampaign.propTypes = {
  id: PropTypes.string,
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  childAgencyHumanFullName: PropTypes.string.isRequired,
  previewStage: PropTypes.bool,
};

export default BulkOutreachCampaign;
